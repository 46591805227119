.aboutUsParentContainer {
    background-color: #ffffff;
    position: relative;
    color: white;
    padding: 18px 0px 0 0;
    font-family: var(--font-primary);
    border-bottom: 2px solid;
    border-image-source: linear-gradient(to right, #fcda04 44%, #fdea29 48%, #fef643 55%, #fffd52 62%, #ffff58 71%, #e0ca25 87%);
    border-image-slice: 1;
}

.headingParent {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.heading {
    font-size: clamp(16px, 3.12vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    max-width: 65vw;
    text-align: center;
}

.aboutUsMainContainer {
    position: relative;
}

.paraParentContainer {
    padding: clamp(0px, 3.6vw, 70px) 0 clamp(150px, 31.2vw, 600px);;
}

.paraContainer {
    font-size: clamp(12px, 1.3vw, 25px);
    line-height: 1.31;
    overflow: auto;
    padding: 20px;
}

.aboutWinZOBG {
    position: absolute;
    bottom: 0;
    top: 0;
    min-width: 120%;
    left: -10%;
}

.hr {
    border-image-source: linear-gradient(to right, #fcda04 44%, #fdea29 48%, #fef643 55%, #fffd52 62%, #ffff58 71%, #e0ca25 87%);
    border-image-slice: 1;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .heading {
        line-height: 0.75;
        letter-spacing: 1px;
    }

    .paraContainer {
        padding-left: 35px;
    }
}