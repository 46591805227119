.mainTrapeziumContainer {
    position: relative;
    margin-top: -4.6vw;
}

.trapeziumBgImage {
    max-width: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
}

.trapeziumBgClip {
    clip-path: polygon(0 4.5vw,15.6% 4.5vw,19.3% 0,80.8% 0,84.4% 4.4vw,100% 4.5vw,100% 100%,0 100%)
}